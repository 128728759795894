// AUTH

import { useEffect, useState } from "react";
import { Link, type LoaderFunctionArgs, redirect } from "react-router";
import { signInExternalUser } from "~/user/signin.server";
import { getAuthInfo, getAuthUser } from "./auth.server";
import { getApiConfig } from "./config.server";

function getAuthUrl(state: { tz: string | null; lc: string | null }) {
  const { client_id, redirect_uri } = getApiConfig();
  const userScopes =
    "users:read,users.profile:read,users:read.email,channels:read,channels:history,groups:read,groups:history,reactions:read";
  return (
    // biome-ignore lint/style/useTemplate: multiline formatting
    "https://slack.com/oauth/v2/authorize" +
    `?client_id=${encodeURIComponent(client_id)}` +
    `&user_scope=${encodeURIComponent(userScopes)}` +
    `&redirect_uri=${encodeURIComponent(redirect_uri)}` +
    `&state=${encodeURIComponent(JSON.stringify(state))}`
  );
}

function parseState(state: string | null): {
  tz?: string;
  lc?: string;
} {
  return state ? JSON.parse(state) : {};
}

export async function loader({ request }: LoaderFunctionArgs) {
  const url = new URL(request.url);
  const code = url.searchParams.get("code");

  if (!code) {
    const tz = url.searchParams.get("tz");
    const lc = url.searchParams.get("lc");
    return redirect(getAuthUrl({ tz, lc }));
  }

  const state = parseState(url.searchParams.get("state"));

  try {
    const auth = await getAuthInfo(code);
    const slackUser = await getAuthUser(auth);

    return await signInExternalUser({
      externalId: slackUser.id,
      sourceType: "slack",
      sourceName: `${auth.teamName} Slack`,
      auth: auth,
      name: slackUser.name,
      firstName: slackUser.firstName,
      email: slackUser.email,
      avatarUrl: slackUser.avatarUrl,
      timeZone: state.tz,
      locale: state.lc,
    });
  } catch (error) {
    console.error("Slack authentication error:", error);
    return redirect("/");
  }
}

export function SignInWithSlack() {
  const [opt, setOpt] = useState(null as { tz: string; lc: string } | null);
  useEffect(() => {
    const options = Intl.DateTimeFormat().resolvedOptions();
    setOpt({ tz: options.timeZone, lc: options.locale });
  }, []);

  return (
    <Link
      to={{
        pathname: "/slack/auth",
        search: opt ? `?tz=${opt.tz}&lc=${opt.lc}` : "",
      }}
      className="border border-input rounded-md bg-white hover:bg-gray-50 hover:text-accent-foreground flex items-center gap-2 px-4 py-2"
    >
      <img src="/slack.svg" alt="Slack" className="w-4 h-4 inline-block" />
      <span>Sign in with Slack</span>
    </Link>
  );
}
