/**
 * Public home page
 */
import type { MetaFunction } from "react-router";
import { Logo } from "./components/logo";
import { SignInWithSlack } from "./slack/auth";

export const meta: MetaFunction = () => {
  return [
    { title: "Sumcast" },
    { name: "description", content: "Get a summary of what is happening in Slack as a daily podcast or email." },
  ];
};

export default function Home() {
  return (
    <div className="flex flex-col h-full max-h-[30em] items-center justify-center p-4 max-w-[38em] mx-auto">
      <Logo className="text-2xl mb-8" />
      <p className="mb-8 text-center text-muted-foreground">
        Get a summary of what is happening in Slack as a daily podcast or email.
      </p>
      <SignInWithSlack />
    </div>
  );
}
